<template>
	<div class="container">
		<div class="title-wrap">
			<h2 class="title">회원가입</h2>
		</div>
		<form class="form" @submit.prevent="register">
			<el-input placeholder="이메일" v-model="email" clearable :disabled="!registerLoad || phase!=1"> </el-input>
			<el-input placeholder="인증번호" v-model="code" clearable :disabled="!registerLoad || phase!=2" v-if="phase == 2"> </el-input>
			<el-input v-if="phase == 3"
				placeholder="비밀번호"
				v-model="password"
				:disabled="!registerLoad || phase!=3"
				show-password
			></el-input>
			<el-input v-if="phase == 3"
				placeholder="비밀번호 확인"
				v-model="password2"
				:disabled="!registerLoad || phase!=3"
				show-password
			></el-input>
			<el-input v-if="phase == 3"
				placeholder="이름 또는 사용자명"
				v-model="name"
				clearable
				:disabled="!registerLoad || phase!=3"
			></el-input>
			<div v-if="phase == 3">
			<el-checkbox v-model="termsAgree" :disabled="!registerLoad || phase!=3" /> 이용약관 및 개인정보취급방침의 모든 내용에 동의합니다.</div><br/>
			<button class="btn-main btn-full mb-20" :disabled="!registerLoad">{{registerButtonText}}</button>
			<p class="error">{{registerErrorText}}</p>
			<el-divider>
				<span class="description">or</span>
			</el-divider>
			<div class="sns-login-wrap">
				<!-- <button class="btn-white btn-full">
					<div class="sns-img icon-large">
						<img src="@/assets/images/sns-google.svg" />
					</div>
					Google 로그인
				</button>
				<button class="btn-white btn-full">
					<div class="sns-img icon-large">
						<img src="@/assets/images/sns-kakao.svg" />
					</div>
					Kakao 로그인
				</button> -->
				<!-- <button class="btn-white btn-full">
					<div class="sns-img icon-large">
						<img src="@/assets/images/sns-naver.svg" />
					</div>
					Naver 회원가입
				</button> -->
			</div>
			<br/>
			<hr />
			<div class="signup-wrap">
				<router-link to="/login" class="find-password">이미 계정이 있으신가요?</router-link>
			</div>
		</form>
	</div>
</template>

<script>
import axios from "axios";
import {apiUrl} from '../constants/config';
export default {
	data() {
		return {
			email: '',
			password: '',
			password2: '',
			phase: 1,
			code: '',
			name: '',
			termsAgree: false,
			email_verify_key: '',
			registerLoad: true,
			registerButtonText: "다음 단계",
			registerErrorText: ""
		}
	},
	methods: {
		register(){
			var vm = this;

			if(vm.phase == 1){

				if(vm.email == "")
				{
					vm.registerErrorText = "이메일을 입력하세요."
					return;
				}
				
				//var regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/; //eslint-disable-line
				var regEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; //eslint-disable-line
				if(this.email.match(regEmail) == null){
					vm.registerErrorText = "올바른 이메일을 입력하세요."
					return;
				}

				vm.registerErrorText = "";
				vm.registerLoad = false;
				vm.registerButtonText = "처리 중";
				axios.post(apiUrl+'/user/register',{
					phase: vm.phase,
					email: vm.email
				}, { withCredentials: true })
				.then(function(response) {
					if(response.data.success)
					{
						vm.email_verify_key = response.data.email_verify_key;
						vm.phase = 2;
						vm.registerLoad = true;
						vm.registerButtonText = "다음 단계";
						vm.registerErrorText = "입력하신 이메일로 인증번호를 보내드렸습니다.";
					}
					else{
						vm.registerLoad = true;
						vm.registerButtonText = "다음 단계";
						vm.registerErrorText = response.data.message;
					}
				})
				.catch(function(error) {
					console.log(error);
				});


			}
			else if(vm.phase == 2){
				if(vm.code == "")
				{
					vm.registerErrorText = "인증번호를 입력하세요."
					return;
				}

				vm.registerErrorText = "";
				vm.registerLoad = false;
				vm.registerButtonText = "처리 중";
				axios.post(apiUrl+'/user/register',{
					phase: vm.phase,
					email: vm.email,
					code: vm.code,
					email_verify_key: vm.email_verify_key
				}, { withCredentials: true })
				.then(function(response) {
					if(response.data.success)
					{
						vm.phase = 3;
						vm.registerLoad = true;
						vm.registerButtonText = "회원가입";
						vm.registerErrorText = "";
					}
					else{
						vm.registerLoad = true;
						vm.registerButtonText = "다음 단계";
						vm.registerErrorText = response.data.message;
					}
				})
				.catch(function(error) {
					console.log(error);
				});

			}

			else {
				if(vm.password == "")
				{
					vm.registerErrorText = "비밀번호를 입력하세요.";
					return;
				}
				if(vm.password2 == "")
				{
					vm.registerErrorText = "비밀번호 확인을 입력하세요.";
					return;
				}
				if(vm.name == ""){
					vm.registerErrorText = "이름 또는 사용자명을 입력하세요.";
					return;
				}
				if(vm.name.length < 3)
				{
					vm.registerErrorText = "이름 또는 사용자명이 너무 짧습니다.";
					return;
				}
				if(vm.name.length > 30)
				{
					vm.registerErrorText = "이름 또는 사용자명이 너무 깁니다.";
					return;
				}

				var specialRule = /[`~!@#$%^&*|\\'";:/?]/gi;
				if(specialRule.test(vm.name)){
					vm.registerErrorText = "이름 또는 사용자명에 특수문자는 포함될 수 없습니다.";
					return;
				}

				if(vm.password != vm.password2)
				{
					vm.registerErrorText = "비밀번호와 비밀번호 확인이 일치하지 않습니다.";
					return;
				}

				if(vm.password.length < 8 || vm.password.length > 20)
				{
					vm.registerErrorText = "비밀번호는 8~20자로 설정해주세요.";
					return;
				}
				if(vm.termsAgree == "")
				{
					vm.registerErrorText = "이용약관 및 개인정보취급방침의 모든 내용에 동의해주세요.";
					return;
				}

				vm.registerErrorText = "";
				vm.registerLoad = false;
				vm.registerButtonText = "처리 중";
				axios.post(apiUrl+'/user/register',{
					phase: vm.phase,
					email: vm.email,
					code: vm.code,
					email_verify_key: vm.email_verify_key,
					password: vm.password,
					name: vm.name,
				}, { withCredentials: true })
				.then(function(response) {
					if(response.data.success)
					{
						vm.$swal.fire(
						'회원가입이 완료되었습니다.',
						"마인페이지를 이용해주셔서 감사합니다.",
						'success'
					).then(() => {
						vm.$router.push("/");
					} 
					)
					}
					else{
						vm.registerLoad = true;
						vm.registerButtonText = "회원가입";
						vm.registerErrorText = response.data.message;
					}
				})
				.catch(function(error) {
					console.log(error);
				});

				

			}
		}
	},
	beforeCreate(){
		if(this.$store.state.user.loggedIn)
			this.$router.push("/");
	}
}
</script>

<style scoped>
.signup-wrap {
	padding-top: 20px;
}

.signup-wrap .find-password {
	float: right;
}

.error {
	color: red;
	font-size: 14px;
}

.sns-login-wrap button {
	margin-bottom: 20px;
	border: 1px solid #e5e5e5;
}

.sns-login-wrap .sns-img {
	width: 24px;
	float: left;
	border-radius: 30px;
	overflow: hidden;
}
</style>
